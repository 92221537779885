import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Theming",
  "order": 10,
  "template": "default",
  "introtext": "Gjør det mulig å tilpasse designsystemet til en annen visuell identitet"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Regler for bruk`}</h2>
    <p>{`Theming er lagt til for at partnerbanker skal kunne ta i bruk designsystemet med deres visuelle profil. Det skal ikke brukes til å gjøre tilpasninger på komponenter som brukes for SpareBank 1.`}</p>
    <h2>{`Bruk av theming`}</h2>
    <p>{`Theming tas i bruk ved å overskrive verdiene som finnes i `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`theme.less`}</code>{`-filene i de ulike pakkene. `}</p>
    <p>{`I FFE-core sin `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`theme.less`}</code>{`-fil defineres mange globale variabler som blir tatt i bruk i de andre pakkene. Skal du f.eks endre font kan du overskrive `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`--ffe-g-font`}</code>{` variablen i ffe-core. `}</p>
    <p>{`For mer komponent-spesifikke ting må verdiene i komponentens egen `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`theme.less`}</code>{`-fil overskrives. Disse variablene er ikke globale og blir kun brukt i komponenten. `}</p>
    <DSExample name="theming_Skjema" mdxType="DSExample" />
    <h2>{`Navngivning`}</h2>
    <p>{`Alle theming-variabler bør ha et navn som beskriver hva variabelen brukes til - f.eks `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`link-color-hover`}</code>{`. Utenom det starter alle theming-variabler på `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`--ffe`}</code>{` og er skrevet i kun lowercase.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`-g-`}</code>{` i navnet betyr at variabelen er global, brukes på tvers av flere ffe-pakker og ligger i ffe-core.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`-v-`}</code>{` betyr at det er en ikke-global variabel, og som kun brukes i pakken den er definert.  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      